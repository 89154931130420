<template>
    <div class="YueyouRoad absolute allh allw overflow-hidden">
        <canvas ref="YueyouRoad" :height="LeftBottomBottom" :width="CurrentW"
                :style="{'transform':'translateX('+(-MoveUnit * MoveCount)+'px)'}"
                class="absolute"></canvas>
        <canvas ref="YueyouRoad1" :height="LeftBottomBottom" :width="CurrentW"
                :style="{'transform':'translateX('+(-MoveUnit * MoveCount)+'px)'}"
                class="absolute nobg"></canvas>
        <img @click="MoveTable('left')" class="absolute pointer" src="../../../public/img/GameCenter/jiantou_left.png">
        <img @click="MoveTable('right')" class="absolute pointer"
             src="../../../public/img/GameCenter/jiantou_right.png">
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        Name: 'YueyouRoad',
        computed: {
            ...mapState(['whdef'])
        },
        props: {
            'LeftBottomBottom': {
                default() {
                    return 0
                }, type: Number
            },
            'RoadList': {
                default() {
                    return {}
                }, type: Object
            },
            'IsAskRoad': [String, Boolean],
            'DefaultCount': {
                default() {
                    return 25
                }, type: Number
            },
            'ImgRootUrl': [String]
        },
        data() {
            return {
                Name: '',
                CurrentW: 1000,
                MoveCount: 0, //表格移动次数
                MoveUnit: 0, //表格移动单位
                MaxX: 0, //最大横坐标
                CountLeftBottomBottom: 0,  //计算出来的高度
            }
        },
        created() {
            this.$nextTick(() => {
                this.Init();
            });
        },
        destroyed() {
            // clearTimeout(this.timer);
        },
        watch: {
            'LeftBottomBottom': {
                handler(n, o) {
                    this.Drawimg();
                }
            },
            'RoadList': {
                handler(n, o) {
                    if (this.ToString(n) === this.ToString(o)) return;
                    this.Drawimg();
                }, deep: true
            },
            'IsAskRoad': {
                handler(n, o) {
                    this.ShowAskRoad(n, 'YueyouRoad', parseInt((this.CountLeftBottomBottom / 6) - 2), 1);
                }
            }
        },
        methods: {
            Init() {
                let Name = this.$route.name;
                this.Name = Name;
            },
            Drawimg() {
                this.CountLeftBottomBottom = this.CountCanvas(this.LeftBottomBottom, 6);   //计算高度
                this.MaxX = 0;  //重置最大位移
                this.MoveCount = 0; //重置位移次数
                this.MoveUnit = this.CountLeftBottomBottom / 6;
                let _this = this;
                let canvas = this.$refs['YueyouRoad'];
                let context = canvas.getContext('2d');
                let cacheCanvas = document.createElement('canvas');
                let cacheContext = cacheCanvas.getContext('2d');
                cacheCanvas.width = this.CurrentW;
                cacheCanvas.height = this.CountLeftBottomBottom;
                let fn = (index, max) => {
                    if (index >= max) return;
                    let item = _this.RoadList['YueyouRoad'][index];
                    if (item.x > _this.MaxX) _this.MaxX = item.x;
                    let img = new Image();
                    if (_this.ImgRootUrl) {
                        img.src = `./img/${_this.ImgRootUrl}/${item.url}.png`;
                    } else {
                        img.src = `./img/${localStorage.type}/${item.url}.png`;
                    }
                    img.onload = function () {
                        let num = parseInt((_this.CountLeftBottomBottom / 6) - 2);
                        let x = num * item.x + (1 * (2 * item.x + 1));
                        let y = num * item.y + (1 * (2 * item.y + 1));
                        cacheContext.drawImage(img, x, y, num, num);
                        if (index === max - 1) {
                            canvas.height = _this.CountLeftBottomBottom;
                            setTimeout(() => {
                                context.drawImage(cacheCanvas, 0, 0);
                            }, 0);
                            if (_this.MaxX >= _this.DefaultCount) _this.MoveCount = _this.MaxX - _this.DefaultCount;
                        }
                        fn(++index, max);
                    }
                }
                if (!this.RoadList['YueyouRoad'] || this.RoadList['YueyouRoad'].length === 0) {
                    canvas.height = this.CountLeftBottomBottom;
                    return;
                }
                fn(0, this.RoadList['YueyouRoad'].length);
            },
            MoveTable(Direction) {
                Direction === 'left' && this.MoveCount > 0 && this.MoveCount--;
                Direction === 'right' && Math.abs(this.MoveCount) < this.MaxX && this.MoveCount++;
            }
        }
    }
</script>

<style lang="less">
    .YueyouRoad {
        &:hover {
            img {
                display: block;
            }
        }

        canvas {
            transition: .3s;
            left: 0;
            top: 0;
            background: url("../../../public/img/Baccarat/xbj.png") repeat-x top left/contain;

            &.nobg {
                background: none;
            }
        }

        img {
            z-index: 10;
            width: .3rem;
            height: .3rem;
            top: 50%;
            transform: translate(0, -50%);
            display: none;
            left: .15rem;

            &:last-child {
                left: auto;
                right: .15rem;
            }
        }
    }
</style>
